














































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class MoneyCards extends Vue {

  @Prop({ default: 0 })
  public amount!: number;

  @Prop({ default: 0 })
  public kurs!: number;

  @Prop({ default: true })
  public gewinn!: boolean;

  @Prop({ default: '' })
  public title!: string;


}

